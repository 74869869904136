import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const Icon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("fill-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99342 4.90435C3.4499 5.00233 3 5.44772 3 6V14.0218C3 14.639 3.28494 15.2216 3.77212 15.6005L10.7721 21.045C11.4943 21.6067 12.5057 21.6067 13.2279 21.045L20.2279 15.6005C20.7151 15.2216 21 14.639 21 14.0218V6C21 5.44772 20.5501 5.00233 20.0066 4.90435C18.5963 4.65013 17.0524 3.93199 15.6367 3.27348C14.202 2.60612 12.899 2 12 2C11.101 2 9.79796 2.60612 8.36326 3.27348C6.94757 3.93199 5.4037 4.65013 3.99342 4.90435ZM11.1398 12.1047C10.9539 12.2947 10.6526 12.2947 10.4667 12.1047L8.98543 10.59C8.79958 10.4 8.49826 10.3999 8.31241 10.59L7.63939 11.2782C7.45354 11.4682 7.45354 11.7763 7.63939 11.9664L10.4667 14.8575C10.6526 15.0475 10.9539 15.0475 11.1398 14.8575L16.3606 9.51891C16.5465 9.32888 16.5465 9.02076 16.3606 8.83072L15.6876 8.14253C15.5017 7.95249 15.2004 7.95249 15.0146 8.14253L11.1398 12.1047Z"
      />
    </svg>
  );
};

export default Icon;
