import {
  BillableRequestApi,
  GetBillableRequestClientsRequest,
  GetBillableRequestNumbersRequest,
  GetBillableRequestsRequest,
  SessionBillingCyclesApi,
} from "@practice/sdk";
import { useSDKApiGetInfiniteWithCursor } from "api-services/endpoints";
import useSWR from "swr";

import { useAuth } from "@contexts/auth";
import { useRequestIdGenerator } from "@hooks/use-request-id-generator";
import { useSDKApi } from "@hooks/use-sdk-api";

export type GetBillableRequestsQueryParams = Omit<
  GetBillableRequestsRequest,
  "xRequestId" | "organizationId"
>;

export const useGetBillableRequests = (
  props: GetBillableRequestsQueryParams
) => {
  const generateRequestId = useRequestIdGenerator("use-get-billable-requests");
  const { oid } = useAuth();
  const billableRequestApi = useSDKApi(BillableRequestApi);

  return useSDKApiGetInfiniteWithCursor(
    `organizations/${oid}/billable-requests?${JSON.stringify(props)}`,
    async (params) => {
      if (!oid) return;
      const res = billableRequestApi.getBillableRequests({
        ...props,
        ...params,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
      return res;
    },
    props,
    {
      dedupingInterval: 1000 * 60 * 60,
      revalidateAll: true,
    }
  );
};

export type GetBillableRequestNumbersQueryParams = Omit<
  GetBillableRequestNumbersRequest,
  "xRequestId" | "organizationId"
>;

export const useGetBillableRequestNumbers = (
  props: GetBillableRequestNumbersQueryParams
) => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-billable-request-numbers"
  );
  const { oid } = useAuth();
  const billableRequestApi = useSDKApi(BillableRequestApi);

  return useSWR(
    `organizations/${oid}/billable-requests/numbers?${JSON.stringify(props)}`,
    async () => {
      if (!oid) return;
      return billableRequestApi.getBillableRequestNumbers({
        ...props,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    {
      dedupingInterval: 1000 * 60 * 10,
    }
  );
};

export type GetBillableRequestClientsQueryParams = Omit<
  GetBillableRequestClientsRequest,
  "xRequestId" | "organizationId"
>;

export const useGetBillableRequestClients = (
  props: GetBillableRequestClientsQueryParams
) => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-billable-request-clients"
  );
  const { oid } = useAuth();
  const billableRequestApi = useSDKApi(BillableRequestApi);

  return useSWR(
    `organizations/${oid}/billable-requests/clients?${JSON.stringify(props)}`,
    async () => {
      if (!oid) return;
      return billableRequestApi.getBillableRequestClients({
        ...props,
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    {
      dedupingInterval: 1000 * 60 * 60,
    }
  );
};

export const useGetSessionBillingCycles = () => {
  const generateRequestId = useRequestIdGenerator(
    "use-get-session-billing-cycles"
  );
  const { oid, aid } = useAuth();
  const billableRequestApi = useSDKApi(SessionBillingCyclesApi);

  return useSWR(
    `${aid}/organizations/${oid}/session-billing/cycles`,
    async () => {
      if (!oid) return;
      return billableRequestApi.getSessionBillingCycles({
        organizationId: oid,
        xRequestId: generateRequestId(),
      });
    },
    { dedupingInterval: 1000 * 60 * 60 }
  );
};
