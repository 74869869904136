import { FC, ReactNode, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { isFunction } from "lodash";
import { SetState } from "types/utils";

import analytics from "@lib/analytics";

import Toggle from "./Form/Toggle";
import CheckIcon from "./Icons/CheckIcon";
import DropdownIcon from "./Icons/DropdownIcon";
import FilterIcon from "./Icons/FilterIcon";
import { Button } from "./Button";
import { ListGrouping } from "./SelectableList";
import Transition from "./Transition";

export type SortByItemType<T> = {
  icon?: any;
  text: string;
  textRight?: React.ReactNode | ((filtered: any[]) => ReactNode);
  value: string;
  disabled?: boolean;
  transform: (items: T[]) => T[];
  groups?: ListGrouping<T>[];
  onClick?: (value: string) => void;
};

export type FilterByItemType<T> = {
  text: string;
  options?: { value: string; text: string }[];
  disabled?: boolean;
  defaultValue?: boolean | string;
  // TODO do we need this passed to UI component
  transform: (items: T[], value: boolean | string) => T[];
  key: string;
  onClick?: (value: boolean | string) => void;
  hidden?: boolean;
};

interface SortDropdownProps {
  items: SortByItemType<any>[];
  filters?: FilterByItemType<any>[];
  className?: string;
  wrapOptionsClassName?: string;
  itemsFooter?: ReactNode;
  selected?: string;
  selectedLabel?: string;
  firstRowLabel?: string;
  queryStringParamName?: string;
  onClickChange?: (value: string) => void;
  filtersMap?: Record<string, string | boolean>;
  setFiltersMap?: SetState<Record<string, string | boolean>>;
  filteredItems: any[];
  filterType?: string;
  CustomFilter?: FC<any>;
}

const SortDropdown: React.FC<SortDropdownProps> = ({
  items,
  filters,
  filtersMap = {},
  setFiltersMap,
  className,
  wrapOptionsClassName,
  onClickChange,
  itemsFooter,
  selected,
  selectedLabel = "Sort by",
  firstRowLabel,
  filteredItems,
  filterType,
  CustomFilter,
}) => {
  const modalRef = useRef();
  const [isSortDropdownOpen, setIsSortDropdownOpen] = useState(false);
  const value = selected || items[0].value;
  const selectedItem = items.find((i) => i.value === value);

  const handleClick = (value: any) => () => {
    onClickChange && onClickChange(value);
    analytics.track({
      event: "component_sort_by",
      properties: {
        sortBy: value,
      },
    });
  };

  const showModal = (e) => {
    if (
      !modalRef?.current?.contains(e.target) &&
      !e.target.className?.includes?.("ignore-click")
    ) {
      setIsSortDropdownOpen(false);
      return;
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", showModal);

    return () => {
      document.removeEventListener("mouseup", showModal);
    };
  }, []);

  const renderOption = <T,>(item: SortByItemType<T>, index: number) => (
    <a
      key={`sortby-item-${item.value}-${index}`}
      className={classNames(
        "flex justify-between items-center w-full text-left px-4 py-3 leading-5 text-black-ink hover:bg-action-950 focus:outline-none focus:bg-action-950",
        !item?.disabled && "cursor-pointer"
      )}
      onClick={!item?.disabled ? handleClick(item.value) : undefined}
    >
      {item?.icon && <span className="mr-2">{item.icon}</span>}
      <span className="flex-1 block">
        {item.text}
        {item?.textRight && isFunction(item.textRight)
          ? item.textRight(item.transform(filteredItems))
          : item.textRight}
      </span>
      {value === item.value && <CheckIcon className="h-[20px]" />}
    </a>
  );

  const renderOptions = (
    <Transition
      ref={modalRef}
      show={isSortDropdownOpen}
      className={classNames(
        "z-50 origin-top-right absolute mt-2 right-0 rounded-md shadow-lg overflow-hidden w-72",
        wrapOptionsClassName
      )}
    >
      <div className="rounded-md bg-white ring-1 ring-black/5">
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="flex justify-between items-center w-full text-left px-4 pt-5 pb-2 leading-5 text-grey-500">
            {firstRowLabel ? firstRowLabel : selectedLabel}
          </div>
          {items.map(renderOption)}
          {itemsFooter}
          {filters?.length ? (
            <div className="border-t border-grey-950 py-2">
              {filters.map((filter) => (
                <div
                  key={`${filter.key}`}
                  className="flex justify-between items-center w-full text-left px-4 py-2 leading-5 text-black-ink"
                >
                  <span className="mr-2">{filter.text}</span>
                  {filter.options ? (
                    <select
                      className="block min-w-0 focus:ring-0 ml-auto h-7 py-0 pl-4 pr-8 rounded-lg bg-grey-950 border-none outline-none ring-0 ignore-click"
                      value={(filtersMap[filter.key] as string) || "all"}
                      onChange={(e) => {
                        setFiltersMap!({
                          ...filtersMap,
                          [filter.key]: e.target.value,
                        });
                        setIsSortDropdownOpen(false);
                        filter.onClick && filter.onClick(e.target.value);
                      }}
                    >
                      {filter.options.map((option) => (
                        <option value={option.value} key={option.value}>
                          {option.text}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Toggle
                      // TODO Fix type if state present, setSetate must be too
                      onChange={() => {
                        setFiltersMap!({
                          ...filtersMap,
                          [filter.key]: !filtersMap[filter.key],
                        });
                        filter.onClick &&
                          filter.onClick(!filtersMap[filter.key]);
                      }}
                      value={filtersMap[filter.key] as boolean}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : null}
        </div>
      </div>
    </Transition>
  );

  const activeDisplayOptions = Object.keys(filtersMap).filter((key) => {
    const filter = filters?.find((f) => f.key === key);
    if (!filter) return false;
    const filterValue = filtersMap[key];
    if (filter.options && filterValue !== "all") return true;
    if (filterValue === true) return true;
    return false;
  });

  const hasDispalyOptions = activeDisplayOptions.length > 0;

  return (
    <div className={classNames("relative inline-block", className)}>
      {CustomFilter ? (
        <CustomFilter
          filterType={filterType}
          onClick={setIsSortDropdownOpen}
          filtersMap={filtersMap}
        />
      ) : (
        <Button small onClick={() => setIsSortDropdownOpen(true)}>
          <FilterIcon className="block sm:hidden" />
          <span className="font-normal hidden sm:block">
            {hasDispalyOptions ? "Options" : selectedItem?.text}
          </span>
          {hasDispalyOptions && (
            <span className="ml-2 rounded-full h-6 w-6 font-sm text-center bg-action-700">
              {activeDisplayOptions.length + 1}
            </span>
          )}
          <DropdownIcon className="inline w-8 h-8 -mt-1 -mr-3" />
        </Button>
      )}
      {renderOptions}
    </div>
  );
};

export default SortDropdown;
