import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const PayrollIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={classNames("stroke-current", className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="3" y="3" width="18" height="18" rx="5" strokeWidth="2" />
      <mask
        id="mask0_6464_2974"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <rect x="2" y="2" width="20" height="20" rx="6" fill="white" />
      </mask>
      <g mask="url(#mask0_6464_2974)">
        <rect x="3" width="6" height="13" strokeWidth="2" />
        <rect x="9" width="6" height="13" strokeWidth="2" />
        <rect x="15" width="6" height="13" strokeWidth="2" />
      </g>
    </svg>
  );
};

export default PayrollIcon;
