import { FC, useCallback, useEffect, useMemo } from "react";
import { compact, has } from "lodash";

import SortByDropdown, {
  FilterByItemType,
  SortByItemType,
} from "@components/SortDropdown";

import usePersistentState from "./use-persistent-state";

export const useListConfiguration = <T,>(
  input: T[] | undefined,
  items: SortByItemType<T>[],
  filters: FilterByItemType<T>[],
  storageKey: string,
  dropdownTitle?: string,
  filterType?: string,
  CustomFilter?: FC<any>
) => {
  const { persistentValue: value, persistentSetValue: setValue } =
    usePersistentState(storageKey, {
      selected: items[0].value,
      filtersMap: filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.key]: filter.defaultValue,
        }),
        {}
      ),
      collapsed: true,
    });

  useEffect(() => {
    const item = items.find((item) => item.value === value.selected);

    if (item?.onClick) item.onClick(value.selected);

    const hasMissingFilters = filters.some(
      (f) => !has(value.filtersMap as any, f.key)
    );

    // This is the only way to add new filters in local storage
    if (!value.filtersMap || hasMissingFilters) {
      const newFilters = filters.reduce(
        (acc, filter) => ({
          ...acc,
          [filter.key]:
            (value.filtersMap as any)?.[filter.key] || filter.defaultValue,
        }),
        {}
      );

      setValue({
        ...value,
        filtersMap: {
          ...value.filtersMap,
          ...newFilters,
        },
      });
    }
  }, [filters, setValue, storageKey, value]);

  const { selected, filtersMap = {}, collapsed } = value as any;

  const getSetter = useCallback(
    (name: string) => (val: any) => {
      const item = items.find((item) => item.value === val);

      if (item?.onClick) item.onClick(val);

      setValue({
        ...value,
        [name]: val,
      });
    },
    [setValue, value]
  );

  const setCollapsed = useCallback(getSetter("collapsed"), [getSetter]);

  const setSelected = useCallback(getSetter("selected"), [getSetter]);

  const setFiltersMap = useCallback(getSetter("filtersMap"), [getSetter]);

  // TODO type
  const transform = useCallback(
    (inputItems: T[] | undefined, ignoreSelect?: boolean) => {
      if (!inputItems) return;
      const transformations = compact([
        !ignoreSelect &&
          items.find((item) => item.value === selected)!.transform,
        ...Object.keys(filtersMap).map((filterKey) => (items: T[]) => {
          const filter = filters.find((f) => f.key === filterKey);
          if (!filter) return items;
          const filterValue = filtersMap[filterKey];
          return filter.transform(items, filterValue);
        }),
      ]);

      let res = inputItems;
      transformations.forEach((transformingFunction) => {
        res = transformingFunction(res);
      });
      return res;
    },
    [filters, filtersMap, items, selected]
  );

  const groups = useMemo(() => {
    return items.find((item) => item.value === selected)?.groups;
  }, [items, selected]);

  return {
    SortDropdown: (
      <SortByDropdown
        className="text-right"
        filtersMap={filtersMap}
        setFiltersMap={setFiltersMap}
        selectedLabel={dropdownTitle}
        items={items}
        selected={selected}
        onClickChange={setSelected}
        filters={filters.filter((f) => !f.hidden)}
        filteredItems={transform(input, true) ?? []}
        filterType={filterType}
        CustomFilter={CustomFilter}
      />
    ),
    data: transform(input) ?? [],
    collapsed,
    setCollapsed,
    groups,
    selectedSort: selected,
    setSelectedSort: setSelected,
    filtersMap,
    setFiltersMap,
  };
};
