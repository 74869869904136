import React, { FC } from "react";
import classNames from "classnames";

import { SVGIconProps } from "@lib/shared-types";

const TemplateIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames("fill-current", className)}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 3C5.55964 3 5 3.55964 5 4.25V19.75C5 20.4404 5.55964 21 6.25 21H17.75C18.4404 21 19 20.4404 19 19.75V6C19 4.34315 17.6569 3 16 3H6.25ZM7 19V5H16C16.5523 5 17 5.44772 17 6V19H7ZM10 11C10.5523 11 11 11.4477 11 12V15C11 15.5523 11.4477 16 12 16C12.5523 16 13 15.5523 13 15V12C13 11.4477 13.4477 11 14 11C14.5523 11 15 10.5523 15 10C15 9.44772 14.5523 9 14 9H10C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11Z"
        fill="#040506"
      />
    </svg>
  );
};

export default TemplateIcon;
