import React, { FC } from "react";

import { SVGIconProps } from "@lib/shared-types";

const ContactCardIcon: FC<SVGIconProps> = ({ className }) => {
  return (
    <svg
      className={className}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 4.5V20.5H18V4.5H6ZM5 2.5C4.44772 2.5 4 2.94772 4 3.5V21.5C4 22.0523 4.44772 22.5 5 22.5H19C19.5523 22.5 20 22.0523 20 21.5V3.5C20 2.94772 19.5523 2.5 19 2.5H5ZM12 13.5C13.1046 13.5 14 12.6046 14 11.5C14 10.3954 13.1046 9.5 12 9.5C10.8954 9.5 10 10.3954 10 11.5C10 12.6046 10.8954 13.5 12 13.5ZM12 15.5C14.2091 15.5 16 13.7091 16 11.5C16 9.29086 14.2091 7.5 12 7.5C9.79086 7.5 8 9.29086 8 11.5C8 13.7091 9.79086 15.5 12 15.5ZM8 16.5C7.44772 16.5 7 16.9477 7 17.5V18.5H17V17.5C17 16.9477 16.5523 16.5 16 16.5H8Z"
        fill="#040506"
      />
    </svg>
  );
};

export default ContactCardIcon;
